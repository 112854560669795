.user-conditions {

		.main-section {
				padding: 60px 0;

				.title-paragraf {
						font-size: 25px;
						margin-bottom: $gutter-y;

						span {
								vertical-align: middle;
								margin-right: 25px;
						}
				}

				.part {

						p {
								max-width: 600px;
						}
				}
		}
}


