.home-page {
	.example-section {
		padding: 40px 0;
		background-color: $bg-body;

		.wr-buttons {
			text-align: center;

			.btn {
				min-width: 240px;
				margin-bottom: 20px;
			}
		}
	}

	.example-title {
		margin-bottom: 30px;
		font-size: 30px;
		text-align: center;
	}
}

.example-flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 20px;
	text-align: center;

	.ex-item {
		flex: 0 1 48%;
		margin-bottom: 15px;
		padding: 20px 8px;
		background-color: #FFFFFF;

		.ex-title {
			font-size: 16px;
		}

		.ex-text {
			display: none;
		}
	}
}

.filter-nav {
	.wr-filter {
		max-width: 260px;
		margin: 0 auto 28px;
		padding: 0;
		list-style: none;
		text-align: center;
	}

	.filter-tag {
		position: relative;
		cursor: pointer;

		a {
			display: block;
			padding: 6px 14px;
			background-color: transparent;
			color: $body-color;
			text-decoration: none;
			transition: color 350ms ease-out, background-color 350ms ease-out;

			&::after {
				display: block;
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 100%;
				height: 0;
				border-bottom: 2px solid transparent;
				content: '';
			}

			&.active {
				background-color: $beige-color;
				color: $black-color;

				&::after {
					border-bottom: 2px solid $yellow-color;
				}
			}

			&:hover {
				&::after {
					border-bottom: 2px solid $yellow-color;
				}
			}
		}
	}
}
@media (min-width: 768px) {
	.home-page {
		.example-section {
			.wr-buttons {
				.btn {
					min-width: 240px;
					margin-bottom: 20px;

					&:first-child {
						margin-right: 20px;
					}
				}
			}
		}
	}

	.filter-nav {
		.wr-filter {
			max-width: 100%
		}

		.filter-tag {
			display: inline-block;
		}
	}
}
@media (min-width: 992px) {
	.example-flex {
		.ex-item {
			flex: 0 1 24%;
			padding: 28px 12px;

			.wr-icon {
				margin-bottom: 14px;
			}

			.ex-title {
				margin-bottom: 12px;
				font-size: 17px;
			}

			.ex-text {
				display: block;
				font-size: 14px;
			}
		}
	}

	.filter-nav {
		.wr-filter {
			margin-bottom: 30px;
			border-bottom: 2px solid $gray-color;
		}

		.filter-tag {
			display: inline-block;
			margin-bottom: 0;
		}
	}
}
