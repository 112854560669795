.mobtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0px;
    background: transparent;
    cursor: pointer;

    span {
        display: block;
        position: relative;
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        transition: background-color 350ms ease-in-out;

        &::before {
            display: block;
            position: absolute;
            top: -10px;
            right: 0;
            width: 32px;
            height: 2px;
            background-color: #ffffff;
            content: "";
            transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
        }

        &::after {
            display: block;
            position: absolute;
            right: 0;
            bottom: -10px;
            width: 32px;
            height: 2px;
            background-color: #ffffff;
            content: "";
            transition: transform 0.3s ease-in-out, bottom 0.3s ease-in-out;
        }
    }

    &.open {

        span {
            background-color: transparent;

            &::before {
                top: 0;
                transform: rotate(45deg);
            }

            &::after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }
}
