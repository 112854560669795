/* Styles common for all pages and sections
=====================================*/
*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $bg-body;
    color: $body-color;
    font-family: 'Poppins', sans-serif;
    font-size: 62.5%;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;

    &.noscroll {
        overflow: hidden;
    }
}

.page-main {
    position: relative;
    flex-grow: 1;
}

.page-footer {
    margin-top: auto;
}

hr {
    margin: 40px 0;
    border: 0;
    border-top: 1px solid $green-color;
    opacity: 0.8;
}

h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.btn {
    display: inline-block;
    padding: 12px 60px;
    border-radius: 6px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 350ms ease-out;
}

.btn-small {
    display: inline-block;
    padding: 6px 14px;
    border-radius: 6px;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    transition: all 350ms ease-out;
}

.btn-normal {
    display: inline-block;
    padding: 10px 16px;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    transition: all 350ms ease-out;
}

.btn-yellow {
    border: 1px solid $yellow-color;
    background-color: $yellow-color;
    color: $btn-color;

    &:hover {
        border: 1px solid $green-color;
        background-color: $green-color;
        color: #FFFFFF;
    }
}

.btn-beige {
    border: 1px solid $green-color;
    background-color: $beige-color;
    color: $green-color;

    &:hover {
        border: 1px solid $yellow-color;
        background-color: $yellow-color;
        color: $btn-color;
    }
}

.btn-black {
    border: 1px solid $btn-color;
    background-color: $btn-color;
    color: #FFFFFF;

    &:hover {
        border: 1px solid $red-color;
        background-color: $red-color;
        color: #FFFFFF;
    }
}

.btn-green {
    border: 1px solid $green-color;
    background-color: $green-color;
    color: #FFFFFF;

    &:hover {
        background-color: #FFFFFF;
        color: $green-color;
    }
}

.btn-beige-green {
    border: 1px solid $green-color;
    background-color: $beige-color;
    color: $green-color;

    &:hover {
        background-color: $green-color;
        color: #FFFFFF;
    }
}

.btn-red {
    border: 1px solid $red-color;
    background-color: $red-color;
    color: #FFFFFF;

    &:hover {
        background-color: #FFFFFF;
        color: $red-color;
    }
}
/* for sprite img */
.bg-arr_down {
    width: 15px;
    height: 15px;
    background: url("../img/css_sprites.png") -10px -10px;
}

.bg-email {
    width: 15px;
    height: 15px;
    background: url("../img/css_sprites.png") -10px -45px;
}

.bg-home {
    width: 15px;
    height: 15px;
    background: url("../img/css_sprites.png") -10px -80px;
}

.bg-phone {
    width: 15px;
    height: 15px;
    background: url("../img/css_sprites.png") -10px -115px;
}

.bg-question {
    width: 15px;
    height: 15px;
    background: url("../img/css_sprites.png") -10px -150px;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: $gutter-x;
    padding-left: $gutter-x;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}
