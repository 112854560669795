.individual-step {
    background-color: #FFFFFF;

    &>.container {
        height: 100%;
    }

    .reg-line {

        .number-step {
            z-index: 1;
            position: relative;

            span {
                display: inline-block;
                width: 38px;
                height: 38px;
                border: 6px solid $gray-color;
                border-radius: 50%;
                background-color: #FFFFFF;
                line-height: 28px;

                &::before {
                    display: block;
                    z-index: -1;
                    position: absolute;
                    top: 15px;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    background-color: $gray-color;
                    content: "";
                }
            }

            &.active {
                span {
                    border: 6px solid $yellow-color;

                    &::before {
                        background-color: $yellow-color;
                    }
                }
            }

            .step-two {
                display: none;

                &+.step-text {
                    display: none;
                }
            }

            .step-three {
                display: none;

                &+.step-text {
                    display: none;
                }
            }

            .step-text {
                margin-top: 6px;
                color: $gray-form;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }

    .flex-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center;
    }

    .page-title {
        margin-top: 20px;
        font-size: 32px;
        font-weight: 500;
    }

    .show-warn {
        height: 18px;
        color: $red-light;
    }

    .main-content {
        margin-bottom: 20px;

        .wr-img {
            margin-bottom: 20px;

            img {
                display: block;
                width: 100%;
            }
        }
    }

    .reg-form {
        .block-text {
            border-bottom: 1px solid $gray-form;
            transition: border-color 350ms ease-out;

            span {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 8px;
                background-image: url("../img/reg-page/form_icons.png");
                vertical-align: middle;

                &.user-ico {
                    background-position: -110px -260px;
                }

                &.email-ico {
                    background-position: -60px -160px;
                }

                &.pwd-ico {
                    background-position: -260px -10px;
                }
            }

            input {
                max-width: 280px;
                margin-bottom: 9px;
                padding: 6px 14px;
                border-top: none;
                border-right: none;
                border-bottom: none;
                border-left: 1px solid $gray-form;
                color: $gray-form;

                &:focus,
                &:focus-visible {
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                    outline: none;
                }
            }

            &.focused {
                border-bottom: 1px solid $yellow-color;

                span {

                    &.user-ico {
                        background-position: -260px -260px;
                    }

                    &.email-ico {
                        background-position: -160px -160px;
                    }

                    &.pwd-ico {
                        background-position: -260px -110px;
                    }
                }

                input {
                    border-left: 1px solid $yellow-color;
                    color: $yellow-color;

                    &::placeholder {
                        color: $yellow-color;
                    }
                }
            }

            &.focused.novalid {
                border-bottom: 1px solid $yellow-color;

                span {

                    &.user-ico {
                        background-position: -260px -260px;
                    }

                    &.email-ico {
                        background-position: -160px -160px;
                    }

                    &.pwd-ico {
                        background-position: -260px -110px;
                    }
                }

                input {
                    border-left: 1px solid $yellow-color;
                    color: $yellow-color;

                    &::placeholder {
                        color: $yellow-color;
                    }
                }
            }

            &.novalid {
                border-bottom: 1px solid $red-light;

                span {

                    &.user-ico {
                        background-position: -210px -260px;
                    }

                    &.email-ico {
                        background-position: -110px -160px;
                    }

                    &.pwd-ico {
                        background-position: -260px -60px;
                    }
                }

                input {
                    border-left: 1px solid $red-light;
                    color: $red-light;

                    &::placeholder {
                        color: $red-light;
                    }
                }
            }

            &.valid {
                border-bottom: 1px solid $black-color;

                span {

                    &.user-ico {
                        background-position: -160px -260px;
                    }

                    &.email-ico {
                        background-position: -10px -160px;
                    }

                    &.pwd-ico {
                        background-position: -210px -210px;
                    }
                }

                input {
                    border-left: 1px solid $black-color;
                    color: $black-color;

                    &::placeholder {
                        color: $black-color;
                    }
                }
            }
        }

        .have-read {

            a {
                color: inherit;
            }

            input[type="checkbox"] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 18px;
                height: 18px;
                margin-top: 2px;
                margin-right: 10px;
                border: 1px solid rgba(0,0,0,.25);
                background-color: #fff;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.873.66L4.447 9.087l-3.32-3.32L0 6.893l4.446 4.447L14 1.786 12.873.66z' fill='%23fff'/%3e%3c/svg%3e");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: top;

                &:checked {
                    background-color: $green-color;
                }
            }
        }
    }

    .btn-weiter {
        margin-bottom: 40px;

        .btn {
            min-width: 184px;

            &:first-child {
                margin-bottom: 18px;
            }
        }
    }
}

@media (min-width: 768px) {

    .individual-step {

        .page-title {
            margin-top: 30px;
            font-size: 40px;
        }

        .reg-line {
            margin-bottom: 24px;
        }

        .main-content {
            display: flex;
            align-items: center;
            justify-content: center;

            .wr-img {
                flex: 0 1 38%;
            }

            .wr-form {
                flex: 0 1 60%;
            }
        }

        .btn-weiter {

            .btn {

                &:first-child {
                    margin-right: 30px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .individual-step {

        .page-title {
            margin-top: 50px;
        }

        .reg-line {
            display: flex;
            justify-content: center;

            .number-step {
                flex: 0 1 33.3333%;

                .step-two {
                    display: inline-block;

                    &+.step-text {
                        display: block;
                    }
                }

                .step-three {
                    display: inline-block;

                    &+.step-text {
                        display: block;
                    }
                }

                &.active {
                    .step-text {
                        color: $black-color;
                        font-weight: 600;
                    }
                }

                &.ready {
                    .step-text {
                        color: $body-color;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
