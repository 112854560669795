.home-page {
	background-color: $bg-contact;

	.main-section {
		padding: 40px 0;

		.flex-wrapper {
			margin-bottom: 20px;

			.info_title {
				line-height: 1.3;
				text-align: center;
			}

			.info-list {
				padding-left: 20px;
				list-style: none;

				li {
					display: inline-block;
					position: relative;
					line-height: 1.6;
					margin-bottom: 4px;

					&::before {
						position: absolute;
						left: -25px;
						top: 2px;
						display: block;
						content: url("../img/star-b.png");
					}
				}
			}

			.flex-img {
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}

		.wr-buttons {
			text-align: center;

			.btn {
				min-width: 240px;
				margin-bottom: 20px;
			}
		}
	}
}
@media (min-width: 768px) {
	.home-page {
		.main-section {
			.flex-wrapper {
				display: flex;
				justify-content: space-between;
				margin-bottom: 40px;

				.flex-info {
					flex: 0 1 54%;
				}

				.flex-img {
					flex: 0 1 42%;
				}
			}

			.wr-buttons {
				.btn {
					min-width: 240px;
					margin-bottom: 20px;

					&:first-child {
						margin-right: 20px;
					}
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.home-page {
		.main-section {
			padding: 60px 0;

			.flex-wrapper {
				margin-bottom: 40px;
			}

			.info_title {
				text-align: left;
			}
		}
	}
}
@media (min-width: 1200px) {
	.home-page {
		.main-section {
			.flex-wrapper {
				display: flex;
				justify-content: space-between;
				margin-bottom: 40px;

				.info_title {
					text-align: left;
				}

				.flex-info {
					flex: 0 1 46%;
				}

				.flex-img {
					flex: 0 1 48%;
				}
			}
		}
	}
}
