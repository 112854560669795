.individual-plans {
    background-color: #FFFFFF;

    &>.container {
        height: 100%;
    }

    .reg-line {

        .number-step {
            z-index: 1;
            position: relative;

            span {
                display: inline-block;
                width: 38px;
                height: 38px;
                border: 6px solid $gray-color;
                border-radius: 50%;
                background-color: #FFFFFF;
                line-height: 28px;

                &::before {
                    display: block;
                    z-index: -1;
                    position: absolute;
                    top: 15px;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    background-color: $gray-color;
                    content: "";
                }
            }

            &.active {
                span {
                    border: 6px solid $yellow-color;

                    &::before {
                        background-color: $yellow-color;
                    }
                }
            }

            .step-one {
                display: none;

                &+.step-text {
                    display: none;
                }
            }

            .step-three {
                display: none;

                &+.step-text {
                    display: none;
                }
            }

            .step-text {
                margin-top: 6px;
                color: $gray-form;
                font-size: 18px;
                font-weight: 500;
            }

            &.active {
                .step-text {
                    color: $black-color;
                    font-weight: 600;
                }
            }
        }
    }

    .flex-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center;
    }

    .page-title {
        margin-top: 20px;
        font-size: 32px;
        font-weight: 500;
    }

    .main-content {
        margin-bottom: 20px;

        .plans-item {
            position: relative;
            max-width: 100%;
            margin-right: auto;
            margin-bottom: 30px;
            margin-left: auto;
            background-color: #FFFFFF;
            box-shadow: 4px 4px 10px rgba(68, 68, 68, 0.25);
            text-align: left;
            cursor: pointer;
            transition: background-color 350ms ease-out, color 350ms ease-out;

            .plans-item__top-info {
                padding: 20px;
            }

            .plans-item__bottom-info {
                padding: 8px 20px;
                border-top: 1px solid $gray-color;
            }

            .plans-title {
                position: relative;
                margin-left: 40px;

                &::before {
                    z-index: 1;
                    position: absolute;
                    bottom: -15px;
                    left: -35px;
                    width: 20px;
                    height: 20px;
                    border: 3px solid $body-color;
                    border-radius: 50%;
                    background-color: #FFFFFF;
                    content: "";
                }
            }

            .price {
                margin-left: 40px;
            }

            .price-year {
                margin-left: 40px
            }

            .title-details {
                margin: 0;
            }

            &:hover {
                background-color: $green-color;
                color: #FFFFFF;

                .plans-title {
                    &::before {
                        border: 3px solid #FFFFFF;
                        background-color: $yellow-color;
                    }
                }
            }

            &.selected {
                background-color: $green-color;
                color: #FFFFFF;

                .plans-title {
                    &::before {
                        border: 3px solid #FFFFFF;
                        background-color: $yellow-color;
                    }
                }
            }
        }
    }

    .btn-weiter {
        margin-bottom: 40px;

        .btn {
            min-width: 184px;

            &:first-child {
                margin-bottom: 18px;
            }
        }
    }
}

@media (min-width: 768px) {

    .individual-plans {

        .page-title {
            margin-top: 30px;
            font-size: 40px;
        }

        .reg-line {
            margin-bottom: 24px;
        }

        .main-content {

            .plans-item {
                max-width: 60%;
            }
        }

        .btn-weiter {

            .btn {

                &:first-child {
                    margin-right: 30px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .individual-plans {

        .page-title {
            margin-top: 50px;
        }

        .reg-line {
            display: flex;
            justify-content: center;

            .number-step {
                flex: 0 1 33.3333%;

                &.ready {
                    span {
                        border: 6px solid $green-light;

                        &::before {
                            background-color: $green-light;
                        }
                    }
                }

                .step-one {
                    display: inline-block;

                    &+.step-text {
                        display: block;
                    }
                }

                .step-three {
                    display: inline-block;

                    &+.step-text {
                        display: block;
                    }
                }

                &.ready {
                    .step-text {
                        color: $body-color;
                        font-weight: 500;
                    }
                }
            }
        }

        .main-content {

            .plans-item {
                max-width: 520px;
            }
        }
    }
}
