.manage-page {

	.sidebar {
		visibility: hidden;
		z-index: 10;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -305px;
		width: 305px;
		padding: 35px 15px;
		background-color: $beige-color;
		opacity: 0;
		transition: left 350ms ease-in-out, visibility 350ms ease-in-out, opacity 350ms ease-out 350ms;

		&.open {
			visibility: visible;
			left: 0;
			opacity: 1;
			transition: left 350ms ease-in-out, visibility 350ms ease-in-out, opacity 350ms ease-in;
		}

		.wr-buttons {
			margin-bottom: 40px;
		}

		.zurueck {
			display: none;
		}

		.hide-menu {
			display: inline-block;
			padding: 9px 24px;
			border: 1px solid $green-color;
			border-radius: 6px;
			background-color: $green-color;
			color: #FFFFFF;
			text-decoration: none;
			transition: all 350ms ease-in-out;
			@include btn-shadow;

			&:hover {
				background-color: #FFFFFF;
				color: $green-color;
			}
		}

		.create-btns {

			a{
				width: 100%;
				margin-bottom: 14px;

				span {
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 10px;
					vertical-align: middle;
				}

				.btn-plus {
					background-image: url("../img/img-cat/btn_sprites.png");
					background-position: -145px -55px;
				}

				&:hover {
					.btn-plus {
						background-position: -145px -95px;
					}
				}
			}
		}

		.title-menu {
			margin-bottom: 10px;
		}

		.divider-menu {
			height: 1px;
			margin: 10px 0 20px;
			border: none;
			background-color: $green-color;
		}

		.folder-menu {
			position: relative;
			margin: 0;
			padding: 8px 0;
			border-bottom: 1px solid $gray-accordion;
			cursor: pointer;
			transition: color 350ms ease-out, background-color 350ms ease-out;

			.folder {
				display: inline-block;
				margin-right: 10px;
				vertical-align: middle;

				img {
					width: auto;
					height: 23px;
				}
			}

			.top-arrow {
				display: block;
				position: absolute;
				top: 14px;
				right: 8px;
				width: 15px;
				height: 15px;
				transition: all 350ms ease-in-out;
			}

			&.active {
				background-color: $pop-mess;
				color: $green-color;

				.top-arrow {
					@include rotate(-180deg);
				}
			}
		}

		.panel {
			max-height: 0;
			overflow: hidden;
			transition: max-height 350ms ease-in-out, overflow 350ms ease-in-out;
		}

		.sub-menu {
			margin: 0;
			padding: 0;
			list-style-type: none;

			.smenu-item {
				margin-bottom: 0;

				a {
					display: block;
					border-bottom: 1px solid $gray-accordion;
					color: inherit;
					line-height: 2.7;
					text-decoration: none;
					transition: all 350ms ease-out;

					&:hover {
						padding-left: 12px;
						border-bottom: 1px solid $yellow-color;
						background-color: $pop-mess;
						color: $red-color;
					}
				}
			}
		}
	}

	.main {
		margin: 35px 15px;

		.show-more {
			margin-bottom: 60px;

			a {
				display: block;
				padding: 10px 15px;
				border: 1px solid $green-color;
				border-radius: 3px;
				background-color: $bg-contact;
				color: inherit;
				text-align: center;
				text-decoration: none;
			}
		}
	}

	.title-mp {
		position: relative;
		flex: 0 1 100%;
		padding-left: 54px;
		font-size: 25px;
		font-weight: 500;
		line-height: 44px;

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 44px;
			height: 44px;
			content: url("../img/img-cat/main-folder-icon.svg");
		}
	}

	.flex-chips {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 40px;

		.item-chip {
			position: relative;
			flex: 0 1 48%;
			margin-bottom: 12px;
			padding: 25px 12px 16px;
			border-radius: 3px;
			background-color: #FFFFFF;
			text-align: center;
			@include tile-shadow;
			transition: background-color 350ms ease-out;

			.title-chip {
				font-size: 14px;
				font-weight: 400;
				transition: color 350ms ease-out;
			}

			.wr-icons {
				z-index: 1;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				padding: 8px;
				opacity: 0;
				transition: opacity 350ms ease-out;

				.left-half {
					float: left;
				}

				.right-half {
					float: right;

					a {
						display: block;
						width: 30px;
						height: 30px;
						padding: 3px 0 0 3px;
						margin-bottom: 8px;
						border-radius: 3px;
						background-color: $beige-color;

						img {
							display: block;
						}

						span {
							display: block;
							width: 25px;
							height: 25px;

							&.img-edit {
								background: url('../img/img-cat/btn_sprites.png') -100px -100px;
							}

							&.img-view {
								background: url('../img/img-cat/btn_sprites.png') -100px -10px;
							}

							&.img-share {
								background: url('../img/img-cat/btn_sprites.png') -10px -55px;
							}

							&.img-delete {
								background: url('../img/img-cat/btn_sprites.png') -10px -100px;
							}
						}
					}
				}
			}

			&:hover {
				background-color: $green-color;

				.title-chip {
					color: #FFFFFF;
				}

				.wr-icons {
					opacity: 1;
				}
			}
		}
	}
}
@media (min-width: 768px) {
	.manage-page {
		.flex-chips {
			.item-chip {
				flex: 0 1 32%;
			}
		}
	}
}
@media (min-width: 992px) {
	.manage-page {
		.flex-chips {
			.item-chip {
				flex: 0 1 24%;
			}
		}
	}
}
@media (min-width: 1200px) {
	.manage-page {
		.sidebar {
			visibility: visible;
			position: static;
			height: 100%;
			padding: 38px 20px;
			float: left;
			opacity: 1;

			.zurueck {
				display: inline-block;
				padding: 9px 24px;
				border: 1px solid $green-color;
				border-radius: 6px;
				background-color: $green-color;
				color: #FFFFFF;
				text-decoration: none;
				transition: all 350ms ease-in-out;
				@include btn-shadow;

				&:hover {
					background-color: #FFFFFF;
					color: $green-color;
				}
			}

			.hide-menu {
				display: none;
			}
		}

		.main {
			margin: 35px 15px 35px 350px;
		}

		.flex-chips {
			.item-chip {
				flex: 0 1 19%;
			}
		}
	}
}
@media (min-width: 1350px) {
	.manage-page {
		.main {
			margin: 35px 35px 35px 350px;
		}

		.flex-chips {
			margin-bottom: 40px;

			.item-chip {
				flex: 0 1 16%;
			}
		}
	}
}
@media (min-width: 1600px) {
	.manage-page {
		.title-mp {
			display: inline-block;
			flex: 0 1 auto;
			margin-bottom: 0;
		}
	}
}
