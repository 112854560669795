.home-page {
	.vorteile-section {
		padding: 40px 0;
	}

	.vorteile-title {
		font-size: 30px;
		text-align: center;
		margin-bottom: 30px;
	}
}

.vor-item {
	margin-bottom: 40px;

	.vor-ico {
		text-align: center;

		img {
			display: inline-block;
			margin-bottom: 18px;
		}
	}

	.wr-vorico {
		width: 95%;
		margin: 0 auto 30px;
		padding: 40px;
		box-shadow: 0 1px 8px 0 rgba(34, 60, 80, 0.12);
		background-color: #FFFFFF;
	}

	.vorangle {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: -8px;
			left: 0;
			width: 22px;
			height: 24px;
			border-top: 2px solid $yellow-color;
			border-right: transparent;
			border-bottom: transparent;
			border-left: 2px solid $yellow-color;
		}

		&::after {
			content: "";
			position: absolute;
			top: -8px;
			right: 0;
			width: 22px;
			height: 24px;
			border-top: 2px solid $yellow-color;
			border-right: 2px solid $yellow-color;
			border-bottom: transparent;
			border-left: transparent;
		}
	}

	.afterangle {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			bottom: 20px;
			left: 0;
			width: 22px;
			height: 24px;
			border-top: transparent;
			border-right: transparent;
			border-bottom: 2px solid $yellow-color;
			border-left: 2px solid $yellow-color;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 20px;
			right: 0;
			width: 22px;
			height: 24px;
			border-top: transparent;
			border-right: 2px solid $yellow-color;
			border-bottom: 2px solid $yellow-color;
			border-left: transparent;
		}
	}
}
@media (min-width: 768px) {
	.vor-item {
		.wr-vorico {
			width: 60%;
		}

		.vorangle {
			width: 63%;
			margin: 0 auto;
		}

		.afterangle {
			width: 63%;
			margin: 0 auto;
		}
	}
}
@media (min-width: 992px) {
	.home-page {
		.vorteile-section {
			padding: 60px 0;
		}

		.vorteile-title {
			margin-bottom: 50px;
		}
	}

	.vor-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.wr-vorico {
			width: 77%;
		}

		.vorangle {
			width: 80%;
			margin: 0 auto;
		}

		.afterangle {
			width: 80%;
			margin: 0 auto;
		}

		.vor-ico {
			flex: 0 1 40%;

			&::after {
				display: block;
				content: "";
				width: 120%;
				height: 2px;
				bottom: 0;
				background-color: $btn-color;
				transition: background-color 350ms ease-in-out;
			}

			img {
				transition: transform 350ms ease-in-out;
			}
		}

		.vor-info {
			flex: 0 1 58%;

			.vor-title {
				font-size: 25px;
			}

			.vor-text {
				font-size: 18px;
			}
		}

		&:nth-child(odd) {
			flex-direction: row-reverse;

			.vor-ico {
				&::after {
					transform: translateX(-20%);
				}
			}
		}

		&:hover {
			.vor-ico {
				&::after {
					background-color: $yellow-color;
				}

				img {
					transform: rotate(15deg);
				}
			}
		}
	}
}
