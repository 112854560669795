.test-editor {
    .main-content {
        img {
            display: block;
            width: 100%;
            height: auto;
        }

        .link-zurueck {
            display: none;
            margin: 30px 0 16px;

            a {
                color: inherit;
            }
        }

        .main-text {
            margin-bottom: 30px;
        }

        .title-section {
            margin: 20px auto 16px;
            font-size: 25px;
            font-weight: 500;
        }

        .subtitle-content {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 1.3rem;
        }

        .mobile-img {
            display: block;
        }

        .desktop-img {
            display: none;
        }
    }
}

@media (min-width: 768px) {
	.test-editor {
        .main-content {

            .mobile-img {
                display: none;
            }

            .desktop-img {
                display: block;
            }
        }
    }
}

@media (min-width: 992px) {
	.test-editor {
        .main-content {
            .link-zurueck {
                display: block;
            }

            .main-text {
                margin-bottom: 50px;
            }

            .desktop-img {
                width: 80%;
                margin: 0 auto;
            }
        }
    }
}
