/*
Template name : Chipfabrik
Version : v1.0.0
Created : 15 april 2021
============================================================== */
$gutter-x: 12px;
$gutter-y: 8px;
$bg-body: #E5E5E5;
$body-color: #444444;
$btn-color: #353535;
$black-color: #121212;
$beige-color: #FFFBF0;
$green-color: #454925;
$green-light: #3BB54A;
$red-color: #892920;
$red-light: #CC1100;
$yellow-color: #FFC107;
$bg-contact: #F2F2F2;
$pop-mess: #FFF9E8;
$menu-admin-color: #6C6C6C;
$gray-color: #CCCCCC;
$gray-accordion: #D4D2D2;
$gray-form: #ABABAB;

@mixin rotate($deg) {
    transform: rotate($deg);
}

@mixin btn-shadow() {
	box-shadow: 2px 2px 10px rgba(191, 191, 191, 0.25);
}

@mixin tile-shadow() {
	box-shadow: 0px 2px 3px rgba(175, 175, 175, 0.2);
}

@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

// my Custom modules
@import "./modules/common";

@import "./modules/header";
@import "./modules/promo";
@import "./modules/user-cond";
@import "./modules/mo-btn";
@import "./modules/home/top-section";
@import "./modules/home/cat-section";
@import "./modules/home/vor-section";
@import "./modules/home/example-section";
@import "./modules/account/account-main";
@import "./modules/account/second-menu";
@import "./modules/account/pop-message";
@import "./modules/account/test-preview";
@import "./modules/account/test-editor";
@import "./modules/reg-page/main-content";
@import "./modules/individual-step/individual-step";
@import "./modules/reg-company/reg-company";
@import "./modules/individual-plans/individual-plans";
@import "./modules/prices-page/prices-page";

@import "./modules/privacy-policy/privacy-policy";
@import "./modules/ad-block";
@import "./modules/footer";

// --breakpoint-xs: 0;
// --breakpoint-sm: 576px;
// --breakpoint-md: 768px;
// --breakpoint-lg: 992px;
// --breakpoint-xl: 1200px;
// --breakpoint-xxl: 1400px;
// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
