.ad-section {
    padding: 60px 40px;
    background-color: $bg-contact;

    p {
        text-align: center;
    }
}

.as-title {
    margin-bottom: 40px;
    font-size: 25px;
    text-align: center;
}
