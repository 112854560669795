.home-page {
    .category-section {
        padding: 40px 0;
        background-color: #892920;
        text-align: center;
    }

    .cat-title {
        color: #FFFFFF;
        font-size: 30px;
    }

    .cat-text {
        margin-bottom: 20px;
        color: #FFFFFF;
        font-size: 18px;
    }
}

.flex-cat {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .cat-item {
        flex: 0 1 49%;
        height: 220px;
        margin-bottom: 10px;

        &:hover {
            .flip-card {
                transform: rotateY(180deg);
            }
        }
    }

    .flip-card {
        position: relative;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        text-align: center;
        transition: transform 0.8s;

        .title-item {
            font-size: 18px;
        }
    }

    .cat-item-front,
    .cat-item-back {
        -webkit-backface-visibility: hidden; /* Safari */
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }

    .cat-item-front {
        padding: 40px 10px;
        background-color: #FFFFFF;

        .block-ico {
            margin-bottom: 16px;
        }

        .text-item {
            display: none;
        }
    }

    .cat-item-back {
        padding: 40px 10px;
        transform: rotateY(180deg);
        background-color: $btn-color;
        color: #FFFFFF;

        .list-item {
            display: none;
        }

        .go {
            display: block;
            position: relative;
            margin-right: 20px;
            color: $yellow-color;
            text-align: right;
            text-decoration: none;

            &:after {
                position: absolute;
                right: 0;
                bottom: -16px;
                transform: scale(3.6);
                content: "\2192";
            }
        }
    }
}
@media (min-width: 768px) {
    .home-page {
        .category-section {
            padding: 60px 0;
        }

        .cat-text {
            margin-bottom: 30px;
        }
    }

    .flex-cat {
        .cat-item {
            flex: 0 1 48%;
            height: 334px;
            margin-bottom: 18px;
        }

        .cat-item-front {
            .text-item {
                display: block;
            }
        }

        .cat-item-back {

            .list-item {
                display: block;
                margin: 16px auto 78px;
                list-style-image: url("../img/star-w.png");
                list-style-type: none;
                text-align: left;
            }
        }
    }
}
@media (min-width: 992px) {
    .home-page {
        .cat-text {
            position: relative;
            width: 70%;
            margin-right: auto;
            margin-bottom: 50px;
            margin-left: auto;

            &::after {
                display: block;
                width: 60%;
                height: 2px;
                margin: 0 auto;
                transform: translateY(12px);
                background-color: $yellow-color;
                content: "";
            }
        }
    }

    .flex-cat {
        .cat-item {
            flex: 0 1 24%;
            margin-bottom: 18px;
        }

        .cat-item-front {
            padding: 40px 20px;

            .text-item {
                height: 72px;
                overflow: hidden;
            }
        }

        .cat-item-back {
            padding: 40px 20px;
        }
    }
}
