.reg-page {
    background-color: #FFFFFF;

    &>.container {
        height: 100%;
    }

    .flex-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .page-title {
        margin-top: 20px;
        font-weight: 500;
        font-size: 32px;
    }

    .page-img {

        img {
            display: block;
            width: 100%;
        }
    }

    .btn-choice {
        margin-bottom: 20px;

        .btn {
            padding: 12px 30px;
            min-width: 285px;

            &:first-child {
                margin-bottom: 18px;
            }
        }

        .active {
            border: 1px solid $yellow-color;
            background-color: $yellow-color;
            color: $btn-color;
        }
    }

    .btn-weiter {
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) {
    .reg-page {

        .page-title {
            font-size: 40px;
            margin-top: 0;
        }

        .page-img {

            img {
                width: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .btn-choice {

            .btn {

                &:first-child {
                    margin-right: 12px;
                    margin-bottom: 0;
                }
            }
        }
    }
}
