.page-footer {
    padding: 40px 15px 0;
    background-color: $body-color;

    .bottom-footer {
        padding:calc(#{$gutter-y} * 2) 0;
        border-top: 1px solid rgba($bg-contact, 0.2);
        color: darken($bg-contact, 30%);
        font-size: 12px;
        text-align: center;
    }
}

.footer-nav ul,
.footer-contact ul {
    margin: 0 0 18px;
    padding: 0;
    color: #ffffff;
    list-style-type: none;
}

.footer-nav li {

    a {
        padding: 10px 8px;
        color: inherit;
        font-size: 18px;
        text-decoration: none;
        transition: color 350ms ease-in-out;

        &.active {
            color: $yellow-color;
        }

        &:hover {
            color: $yellow-color;
        }
    }
}

.footer-contact li {
    padding: 4px 8px;

    a {
       color: inherit;
       text-decoration: none;
   }
}

.info-footer {

    .foot-ico {
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
    }

    .wr-foot-logo {

        a {
            display: inline-block;
            padding: 0 8px;
        }

        img {
            display: block;
        }
    }
}

@media (min-width: 768px) {
    .info-footer {
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width: 992px) {
    .page-footer {
        padding: 40px 65px 0;
    }

    .info-footer {

        .wr-info {
           flex: 0 1 68%;
        }

       .wr-foot-logo {
           flex: 0 1 30%;
           text-align: right;

           p {
               margin: 0;
           }
       }
    }

    .footer-nav {

        ul {
            li {
                display: inline-block;
            }
        }
    }

    .footer-contact {

        ul {
            margin: 0 0 30px;

            li {
                display: inline-block;
            }
        }
    }
}
