.top-nav {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 11px 15px;
    background-color: $green-color;

    .wr-menu-logo {

        a {
            display: inline-block;
        }

        img {
            display: inline-block;
            width: 78px;
            margin-right: 18px;
            vertical-align: middle;
        }

        .top-menu {
            display: inline-block;
            visibility: hidden;
            z-index: 2;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            max-height: 0;
            overflow: hidden;
            background-color: $red-color;
            opacity: 0;
            transition: max-height .6s ease-out, visibility .4s ease-out .4s, opacity .4s ease-out .4s;

            &.open {
                visibility: visible;
                max-height: 50vh;
                opacity: 1;
                transition: max-height .9s ease-out, visibility .4s ease-out, opacity .4s ease-out;
            }

            ul {
                margin: 0;
                padding: 20px 20px 20px 55px;
                list-style: none;
            }

            li {
                display: block;
            }

            a {
                display: inline-block;
                padding: 10px 8px;
                color: #FFFFFF;
                text-decoration: none;
                transition: color 350ms ease-in-out;

                &.active {
                    color: $yellow-color;
                }

                &:hover {
                    color: $yellow-color;
                }
            }
        }

        .wr-mobtn {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.wr-menu-admin {
    position: relative;
    padding: .375rem 2.25rem .375rem .75rem;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
    background-position: right .75rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;

    .user-link {
        display: inline-block;
        color: #FFFFFF;
        vertical-align: middle;
        cursor: pointer;
    }

    .user-avatar {
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;

        img {
            display: block;

        }
    }

    .user-name {
        display: inline-block;
        margin-right: 6px;
        color: #FFFFFF;
        vertical-align: middle;
        cursor: pointer;

        &::after {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
        }
    }

    .wr-account-nav {
        z-index: 12;
        position: absolute;
        top: 46px;
        right: 0;
        width: 240px;
        max-height: 0;
        overflow: hidden;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 3px 1px rgba(191, 191, 191, 0.2);
        transition: max-height 450ms ease-out, visibility 450ms ease-out;
        visibility: hidden;

        &.open {
            max-height: 555px;
            visibility: visible;
        }

        .account-nav {
            margin: 0;
            padding: 16px;
            list-style: none;
        }

        .accout-nav-item {
            display: block;
            border-bottom: 1px solid $gray-color;
            font-size: 15px;
            line-height: 2.4;
            transition: all 350ms ease-out;

            &:last-child {
                border-bottom: none;
            }

            .item-ico {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 4px;
                overflow: hidden;
                vertical-align: middle;
                transition: all 350ms ease-out;
            }

            .bg-abo {
                background: url('../img/img-cat/menu-admin-sprites.png') -10px -90px;
            }

            .bg-abmelden {
                background: url('../img/img-cat/menu-admin-sprites.png') -10px -10px;
            }

            .bg-einstellungen {
                background: url('../img/img-cat/menu-admin-sprites.png') -10px -170px;
            }

            .bg-rechnungen {
                background: url('../img/img-cat/menu-admin-sprites.png') -10px -250px;
            }

            a {
                display: inline-block;
                color: $menu-admin-color;
                text-decoration: none;
                transition: color 350ms ease-out;
            }

            &:hover {
                border-bottom: 1px solid $yellow-color;

                &:last-child {
                    border-bottom: none;
                }

                .bg-abo {
                    background: url('../img/img-cat/menu-admin-sprites.png') -10px -130px;
                }

                .bg-abmelden {
                    background: url('../img/img-cat/menu-admin-sprites.png') -10px -50px;
                }

                .bg-einstellungen {
                    background: url('../img/img-cat/menu-admin-sprites.png') -10px -210px;
                }

                .bg-rechnungen {
                    background: url('../img/img-cat/menu-admin-sprites.png') -10px -290px;
                }

                a {
                    color: $green-color;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .top-nav {
        padding: 11px 65px;

        .wr-menu-logo {

            img {
                width: auto;
            }

            .top-menu {
                display: inline-block;
                visibility: visible;
                position: static;
                max-height: 100%;
                background-color: var(--bg-hero);
                vertical-align: middle;
                opacity: 1;

                ul {
                    padding: 0 0 0 40px;
                }

                li {
                    display: inline-block;
                }
            }

            .wr-mobtn {
                display: none;
            }
        }
    }
}
