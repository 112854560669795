.test-preview {

	.sidebar {
        padding: 35px 15px;
        background-color: $beige-color;

		.wr-buttons {
			margin-bottom: 40px;
		}

		.zurueck {
			display: inline-block;
			padding: 9px 24px;
			border: 1px solid $green-color;
			border-radius: 6px;
			background-color: $green-color;
			color: #FFFFFF;
			text-decoration: none;
			transition: all 350ms ease-in-out;
			@include btn-shadow;

			&:hover {
				background-color: #FFFFFF;
				color: $green-color;
			}
		}

        .title-menu {
            color: $black-color;
            font-size: 25px;
            margin-bottom: 1.5rem;
        }

        .list-menu {
            list-style: none;
            padding: 0;
            margin: 0 0 1rem;

            .menu-item {
                line-height: 2;

                a {
                    display: block;
                    padding: 5px 12px;
                    color: inherit;
                    text-decoration: none;
                    border: 1px solid transparent;
                    border-bottom: 1px solid $gray-color;
                    border-radius: 1px;

                    &.active {
                        border: 1px solid #FFC107;
                        transform: translateY(-1px);
                        background-color: $pop-mess;
                        font-weight: 500;
                        border-radius: 3px;
                    }

                    span {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .main-section {
        margin: 35px 0;
    }

    .main-content {
        padding: 30px 12px;
        background-color: #FFFFFF;
    }

    .title-section {
        font-size: 30px;
        font-weight: 500;
        margin: 50px auto;
        text-align: center;
    }
}

@media (min-width: 1200px) {
	.test-preview {
		.sidebar {
			visibility: visible;
			position: static;
			height: 100%;
            width: 305px;
			padding: 60px 20px;
			float: left;
			opacity: 1;
		}

		.main-section {
			margin: 35px 15px 35px 350px;
		}

        .main-content {
            padding: 40px;
        }
	}
}

@media (min-width: 1350px) {
	.test-preview {
		.main-section {
			margin: 35px 35px 35px 350px;
		}
    }
}
