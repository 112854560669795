.privacy-policy {

    .main-section {
        padding: 40px 0 20px;
    }

    .title-paragraf {
        font-size: 22px;
    }

    .subtitle-paragraf {
        font-size: 20px;
    }

    .main-text {
        max-width: 700px;
    }

    .mark-text {
        box-sizing: border-box;
        max-width: 845px;
        padding: 20px 40px;
        border-radius: 6px;
        color: #ffffff;
    }

    .red-text {
        background-color: $red-color;
    }

    .gruene-text {
        background-color: $green-color;

        a {
            color: $yellow-color;
        }
    }

    .small-hr {
        width: 300px;
        margin: 30px 0;
    }
}

@media (min-width: 992px) {
	.privacy-policy {

    .main-section {
				padding: 60px 0 30px;
		}
	}

}
	