.pp-body {
    background-color: #FFFFFF;
}

.prices-page {
    margin-bottom: 20px;
    padding: 40px 0 20px;
    border-bottom: 1px solid $gray-color;
    background-color: #FFFFFF;

    .page-title {
        font-size: 32px;
        font-weight: 500;
    }

    .f-advert-img {
        margin-bottom: 20px;

        img {
            display: block;
            width: 100%;
        }
    }
}

.price-plans {
    background-color: #FFFFFF;

    .price-title {
        text-align: center;
    }

    .tab-buttons {

        .tab-head {
            margin-bottom: 10px;
            padding: 8px 8px;
            background-color: $bg-contact;
            font-size: 18px;

            &.active {background-color: $green-color;
                color: #FFFFFF;
            }
        }
    }

    .tab-panels {
        margin-bottom: 30px;

        .m-schield {
            margin: 30px 25px 40px;
            padding: 40px 15px;
            border: 2px solid $green-color;
            border-radius: 6px;
            transition: all 350ms ease-out;
            text-align: center;

            &:hover {
                border: 2px solid $yellow-color;
            }

            .btn-yellow {
                font-weight: 400;
            }
        }

        .mcell-head {
            font-size: 25px;
            font-weight: 500;
        }

        .mcell-text {
            max-height: 48px;
            overflow: hidden;
            font-weight: 400;
        }

        .mcell-price {
            font-size: 22px;
        }

        .panel {
            display: none;

            &.active {
                display: block;
            }
        }

        .feature-wrap {
            display: flex;
            justify-content: space-between;
            padding: 0 8px;
            border-top: 1px solid $gray-color;

            span {
                display: inline-block;
                margin-top: 9px;
                margin-bottom: 8px;
                font-size: 14px;

                .icon {
                    display: block;
                    width: 25px;
                    height: 25px;
                }
            }

            &:nth-child(even) {
                background-color: $bg-contact;
            }

            &:last-child {
                border-bottom: 1px solid $gray-color;
            }
        }
    }

    .wr-btn {
        text-align: center;
        margin-bottom: 50px;

        p {
            font-size: 25px;
        }
    }
}

@media (min-width: 576px) {
    .price-plans {

        .tab-panels {

            .m-schield {
                margin: 30px auto 40px;
                max-width: 300px;
            }
        }
    }
}

@media (min-width: 768px) {

    .prices-page {
        margin-bottom: 50px;
        padding: 60px 0 30px;

        .page-title {
            font-size: 40px;
        }

        .f-advert {
            display: flex;
            align-items: center;
            justify-content: center;

            .f-advert-info {
                flex: 0 1 50%;
            }

            .f-advert-img {
                flex: 0 1 50%;
            }
        }
    }
}

@media (min-width: 992px) {

    .price-plans {

        .table-price {
            margin-bottom: 40px;
            border-collapse: collapse;

            tbody {

                tr {
                    border-top: 1px solid $gray-color;
                    border-bottom: 1px solid $gray-color;

                    &:nth-child(even) {
                        background-color: $bg-contact;
                    }
                }

                th {
                    padding: 18px 0;
                    font-size: 18px;
                    font-weight: 400;
                    text-align: left;
                }

                td {
                    padding: 18px 0;
                    text-align: center;

                    .icon {
                        width: 25px;
                        height: 25px;
                    }
                }
            }

            .th-first {
                width: 24%;
                font-size: 45px;
            }

            .tcell-remains {
                width: 20%;
            }

            .schield {
                margin: 15px 15px 50px;
                padding: 40px 15px;
                border: 2px solid $green-color;
                border-radius: 6px;
                transition: all 350ms ease-out;

                &:hover {
                    border: 2px solid $yellow-color;
                }

                .btn-yellow {
                    font-weight: 400;
                }
            }

            .cell-head {
                font-size: 25px;
                font-weight: 500;
            }

            .cell-text {
                max-height: 48px;
                overflow: hidden;
                font-weight: 400;
            }

            .cell-price {
                font-size: 22px;
            }
        }

        .wr-btn {
            margin-bottom: 80px;
        }
    }
}
