.promo-section {
    padding: 40px 0;
    background-color: $green-color;

    .title-hero {
        color: #ffffff;
        font-size: 28px;
        font-weight: 500;
        text-align: center
    }

    .text-hero {
        color: #ffffff;

        p:last-child {
            margin: 0 0 30px;
        }
    }

    .subtitle-hero {
        font-size: 20px;
    }

    .img-hero img {
        display: block;
        width: 100%;
        height: auto;
    }
}

@media (min-width: 576px) {

    .promo-section {

        .img-hero img {
            width: auto;
            margin: 0 auto;
        }
    }

}

@media (min-width: 992px) {
    .promo-section {
        padding: 80px 0;

        .title-hero {
            margin-bottom: 40px;
            font-size: 40px
        }

        .flex-container {
            display: flex;
        }

        .text-hero {
            flex: 0 1 49%;
        }

        .img-hero {
            flex: 0 1 49%;
            text-align: center;
        }

        .text-hero {
            font-size: 18px;
        }
    }
}
